// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-templates-base-overview-tpl-tsx": () => import("./../../src/templates/BaseOverview.tpl.tsx" /* webpackChunkName: "component---src-templates-base-overview-tpl-tsx" */),
  "component---src-templates-base-single-tpl-tsx": () => import("./../../src/templates/BaseSingle.tpl.tsx" /* webpackChunkName: "component---src-templates-base-single-tpl-tsx" */)
}

